@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/ubuntu-v20-latin_cyrillic-regular.woff2")
      format("woff2"),
    url("./assets/fonts/ubuntu-v20-latin_cyrillic-regular.woff") format("woff");
}
@font-face {
  font-family: "Ubuntu";
  font-style: bold;
  font-weight: 700;
  src: url("./assets/fonts/ubuntu-v20-latin_cyrillic-700.woff2") format("woff2"),
    url("./assets/fonts/ubuntu-v20-latin_cyrillic-700.woff") format("woff");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}
html,
body {
  min-height: 100%;
}
body {
  font-size: 1.6rem;
  font-family: "Ubuntu", sans-serif;
}
h1 {
  margin: 2rem;
  text-align: center;
}
