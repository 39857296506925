.navbar {
  position: relative;
  background-color: #0e1400;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #fff;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.5);
}
.logo {
  font-weight: 900;
  font-size: 2rem;
  padding: 1rem;
}
.toggleButton {
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 25px;
  height: 20px;
  cursor: pointer;
}
.bar {
  width: 100%;
  height: 2px;
  background-color: #fff;
}
.navlinks {
  position: fixed;
  top: 43px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-inline-end: 1rem;
  background-color: inherit;
  transform: translateX(100%);
  transition: transform 0.5s ease-out;
}
.navlinks.active {
  transform: translateX(0%);
}
.activeRoute {
  background-color: #557b00;
}
.navlinks li {
  list-style: none;
  text-align: center;
  transition: background-color 0.3s;
}
.navlinks li a {
  text-decoration-color: transparent;
  display: block;
  padding: 1.4rem 1.8rem;
  color: inherit;
  transition: text-decoration-color 0.6s;
}
.navlinks li:hover {
  background-color: #80b900;
}
.navlinks li:hover a {
  text-decoration-color: #fff;
}

@media (min-width: 500px) {
  .navbar {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .toggleButton {
    display: none;
  }
  .navlinks {
    position: static;
    display: flex;
    flex-direction: row;
    width: initial;
    transform: none;
  }
}
